.text__container {
  text-align: center;
  color: #7a7a7a;
  font-size: 24px;
}

h1 {
  color: #545454;
  margin-top: 10px;
}

.content {
  padding-top: 5vw;

  padding-bottom: 10vw;
}

.about {
  background-color: #ffffff;
}

.another__text {
  color: #f37fda;
  margin-bottom: 30px;
}

.our__team {
  background-color: #fff6e6;
  color: #708d89;
}

.our__team>h1 {
  color: #2f4858;
}

.div__team__text {
  margin-bottom: 40px;
}

.div__card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.sub__part__ourTeam {
  margin-top: 80px;
  margin-bottom: 20px;
}

.our__vision {
  color: #000000;
}

.vision__sub__text {
  margin-top: 10px;
  font-size: 16px;
  color: #b1afaf;
  font-weight: 600;
}

.sec__h1 {
  margin-top: 70px;
}

.our__partners {
  background-color: #fcfcfb;
}

.contact__us {
  color: #242424;
}

.contact__us>h1 {
  color: #202020;
}

.div__contact {
  margin-top: 20px;
}

.gallery {
  background-color: #f7f7f7;
}

@media (max-width: 768px) {
  h1 {
    margin-top: 70px;
  }
}

@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

@font-face {
  font-family: 'Cafe24Ssurround';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/Cafe24Ssurround.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SUIT-Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.scrollRemoveBox {
  overflow: scroll;
  -ms-overflow-style: none;
  /* 익스플로러, 앳지 */
  scrollbar-width: none;
  /* 파이어폭스 */
}

.scrollRemoveBox::-webkit-scrollbar {
  display: none;
  /* 크롬, 사파리, 오페라 */
}

.emailIcon:hover {
  color: #FFF !important;
  background-color: #333 !important;
}